<template>
    <div class="wrapper">
        <!-- 页面头部部分 -->
        <div class="header">
            <div class="row_title_left">
                <div class="logo">
                    <img src="@/assets/img/photo.png" alt="风电机组网络化协同设计与制造平台" />
                    <div class="company-title" style="color: #ffffff">风电机组网络化协同设计与制造平台</div>
                </div>
            </div>

            <div class="row_title_middle">
                  <el-menu
                    mode="horizontal"
                    background-color="#2B3F54"
                    text-color="#fff"
                    active-text-color="#3F9DFF"
                    :default-active="toIndex"
                    @select="handleSelect"
                >
                    <el-menu-item  v-for="(item, index) in itemList" :index="item.path" :key="index">
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                    <!-- <el-submenu index="2" v-show="subShow">
                        <template slot="title">菜单</template>
                        <el-menu-item v-for="(item, index) in itemList" :index="item.path" :key="index">{{ item.title }}</el-menu-item>
                    </el-submenu> -->
                </el-menu>
            </div>
            <div class="row_title_right">
                <div class="right_info" style="margin-right: 10px">
                    <el-popover placement="bottom" width="200" trigger="hover">
                        <ul class="notice-list">
                            <li class="notice-list-item" v-for="num in 6" :key="num">零件编号CNN{{ num }}需要{{ num * 10 }}个</li>
                        </ul>
                        <div slot="reference">
                            <el-badge is-dot>
                                <el-icon name="bell" class="icon" style="font-size: 30px" />
                            </el-badge>
                        </div>
                    </el-popover>
                </div>

                <div style="margin-right: 10px; float: right">
                    <span style="color: #fcfcfc">{{ usertype }}</span>
                    <span style="margin: 0 5px; color: #afafaf">|</span>
                    <el-tooltip style="color: #fcfcfc" effect="dark" :content="funllCompany" placement="bottom">
                        <el-button type="text">{{ company }}</el-button>
                    </el-tooltip>
                    <span style="margin: 0 5px; color: #afafaf">|</span>
                    <el-dropdown @command="handleCommand" trigger="hover">
                        <span>
                            <div class="my_logo">
                                <el-link
                                    type="primary"
                                    :underline="false"
                                    style="width: 90px; margin-left: -20px; line-height: 20px; margin-bottom: 3px"
                                    ><span class="my_set">{{ name }}</span>
                                    <i class="el-icon-arrow-down el-icon--right" style="color: #afafaf"></i>
                                </el-link>
                            </div>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="restPassword" align="center">修改密码</el-dropdown-item>
                            <el-dropdown-item command="home" align="center">首页</el-dropdown-item>

                            <el-dropdown-item command="signout" align="center">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <el-dialog title="修改密码" :visible.sync="restPassDialog" width="30%" :before-close="CancelPass">
                <el-form
                    :model="resetPasswordFrom"
                    status-icon
                    :rules="passRule"
                    ref="resetPasswordFrom"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="旧密码" prop="password">
                        <el-input
                            v-model="resetPasswordFrom.password"
                            placeholder="请输入旧密码"
                            clearable
                            show-password
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input
                            v-model="resetPasswordFrom.newPassword"
                            placeholder="请输入新密码"
                            clearable
                            show-password
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="aginnewPassword">
                        <el-input
                            v-model="resetPasswordFrom.aginnewPassword"
                            placeholder="请输入新密码"
                            clearable
                            show-password
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="pass_footer">
                            <el-button @click="CancelPass">取消</el-button>
                            <el-button type="primary" @click="EnterData('resetPasswordFrom')">确定</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>

        <!-- 页面左侧二级菜单栏，和主体内容区域部分 -->
        <div class="mian">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
let passwordtext = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;

let password = (rule, value, callback) => {
    if (!passwordtext.test(value)) {
        return callback(new Error('密码格式数字加字母8~16位'));
    } else {
        callback();
    }
};
import bus from '@/utils/bus';
import { userResetPass } from '@/api/user';
export default {
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.resetPasswordFrom.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            screenWidth: '',
            screenHeight: '',
            headerShow: true,
            subShow: false,
            itemList: [],
            globalTheme: false,
            company: null,
            name: '',
            userImg: '',
            usertype: null,
            activeIndex: 'index',
            restPassDialog: false,
            resetPasswordFrom: {},
            funllCompany:  localStorage.getItem('company'),
            passRule: {
                newPassword: [
                    {
                        type: 'string',
                        required: true,

                        validator: password,

                        trigger: 'blur'
                    }
                ],
                aginnewPassword: [
                    { required: true, message: '请确认密码', trigger: 'blur' },
                    {
                        type: 'string',
                        required: true,
                        validator: validatePass2,

                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    computed: {
        toIndex() {
            // 根据路径绑定到对应的一级菜单，防止页面刷新重新跳回第一个
            return '/' + this.$route.path.split('/')[1];
        }
    },
    created() {
        this.globalTheme = JSON.parse(localStorage.getItem('global_theme'));
        bus.$emit('global_theme', this.globalTheme); // 将 globalTheme 的值传给父组件
    },
    watch: {
        screenWidth(oldVlaue, nowVlaue) {
            console.log(oldVlaue, nowVlaue);
            if (nowVlaue > 1500) {
                this.headerShow = true;
                this.subShow = false;
            } else if (nowVlaue <= 1309) {
                this.headerShow = false;
                this.subShow = true;
            }
        },
        // 窗口高度改变
        screenHeight(n, o) {}
    },
    mounted() {
        this.onGetData();
          // 监听窗口变化
        let _this = this;
        window.addEventListener('resize', function () {
            _this.screenWidth = document.body.clientWidth;
            _this.screenHeight = document.body.clientHeight;
        });
    },
    methods: {
        handleSelect(path, name) {
            // 切换菜单栏
            console.log(path, name);
            localStorage.setItem('title', path);
            this.$router.push({
                path: path
            });
        },

        handleCommand(command) {
            // 用户名下拉菜单选择事件
            if (command == 'loginout') {
                localStorage.removeItem('ms_username');
                this.$router.push({
                    path: '/Login'
                });
            }
        },
        onGetData() {
            let data = JSON.parse(sessionStorage.getItem('user')) || {};
            // console.log(data);
            this.usertype = localStorage.getItem('userType');
            const company = localStorage.getItem('company');
      
            // console.log(localStorage.getItem("userType"));
            this.name = localStorage.getItem('name');
            this.userImg = data.avatar || '';
            if (company.length > 18) {
                this.company = company.substring(0,  company.length - (company.length - 18)) + '...'
            } else {
                this.company = company
            }
            let adminNavList = [
                    { path: '/Home', title: '主页' },
                    { path: '/Data', title: '资料管理' },
                    { path: '/CollaborativeManagement', title: '协同管理' },
                    { path: '/User', title: '用户信息管理' }
                ],
                userNavLsit = [
                    { path: '/Home', title: '主页' },
                    { path: '/Data', title: '资料管理' },
                    { path: '/CollaborativeManagement', title: '协同管理' }
                ];

            if (this.usertype == '超级管理员') {
                this.itemList = adminNavList;
            } else {
                this.itemList = userNavLsit;
            }
        },
        handleCommand(command) {
            if (command === 'home') {
                this.$router.push('/index');
            } else if (command === 'signout') {
                localStorage.removeItem('token');
                this.$store.commit('LogoOut', '');
                this.$message({
                    type: 'success',
                    message: '退出成功'
                });
                this.$router.push('Login');
                // localStorage.clear();
            }
        },

        restPassword() {
            this.restPassDialog = true;
        },
        EnterData(resetPasswordFrom) {
            let data = {};
            data.password = this.resetPasswordFrom.password;
            data.newPassword = this.resetPasswordFrom.newPassword;

            this.$refs[resetPasswordFrom].validate(async (valid) => {
                if (valid) {
                    let res = await userResetPass(data);
                    if (res.code == 200) {
                        this.restPassDialog = false;
                        this.resetPassword = {};
                        this.$message.success('修改密码成功,请重新登录');
                        localStorage.removeItem('token');
                        setTimeout((v) => {
                            this.$router.push('/Login');
                        }, 1000);
                    } else {
                        this.resetPassword = {};
                        this.$message.error('旧密码错误，请重新输入');
                    }
                } else if (this.resetPasswordFrom.password == '' || this.resetPasswordFrom.password == undefined) {
                    return this.$message.error('请输入旧密码');
                } else if (this.resetPasswordFrom.newPassword !== this.resetPasswordFrom.aginnewPassword) {
                    return this.$message.error('两次密码不一样，请重新输入');
                }
            });
        },

        CancelPass() {
            this.restPassDialog = false;
            this.resetPasswordFrom = {};
        }
    }
};
</script>

<style scoped lang ='scss'>
.wrapper {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    overflow: hidden;
}
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    background: rgb(43, 63, 84);
    color: #f0f0f0;
}

/* --------------- 水平一级菜单栏的样式--------------------- */
.el-menu.el-menu--horizontal {
    border-bottom: none !important;
    float: left;
    margin-left: 50px;
    background: transparent;
}
.el-menu--horizontal > .el-menu-item.is-active {
    /* border-bottom: 2px solid #3989fa;
  color: #3989fa; */
    font-weight: bold;
}
.el-menu--horizontal > .el-menu-item {
    margin: 0 15px;
}
.mian {
    background: #f0f0f0;

    height: 100%;
}

.row_title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
::v-deep.el-popover__reference {
    list-style: none;
}
.notice-list-item {
    list-style: none;
    text-align: center;
}

.row_title_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 100%;
    color: #ffffff;

    .username {
        width: 55%;
        color: #ffffff;
        .my_name {
            width: 50%;
            margin-right: 20px;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .my_name_text {
            width: 100%;
            box-sizing: border-box;
        }

        .my_type {
            width: 50%;
            color: #ffffff;
        }
        color: gray;
        height: 30px;
        margin-left: 10px;
        width: 230px;

        font-size: 14px;
        letter-spacing: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
::v-deep .el-link--inner {
    font-size: 18px;
    margin-right: 20px;
}

::v-deep .el-badge {
    font-size: 14px;
}
.row_title_left {
    .logo {
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        img {
            margin-left: 30px;
            width: 50px;
            height: 50px;
        }
    }
}
::v-deep .el-link.el-link--primary {
    color: #eee;
    position: relative;
    left: 20px;
    width: 70px;
    box-sizing: border-box;
}

.row_title_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    width: 800px;
    height: 100%;
    color: #ffffff;

    .username {
        .my_name,
        .my_type {
            color: #ffffff;
        }
        color: gray;
        height: 30px;
        margin-left: 10px;
        width: 230px;
        font-size: 14px;
        letter-spacing: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
::v-deep .el-link--inner {
    font-size: 18px;
    margin-right: 20px;
}

.el-icon--right {
    color: #eee;
    font-size: 18px;
}
.my_logo {
    width: 90px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .my_set {
        font-size: 14px;
    }
}
</style>